import React from "react"
import "./projects.css"

const Projects = () => {
  return (
    <section className="container-fluid" id="projects">
      <h2 className="main-heading">Projects</h2>

      <div className="projects-container">
        <div className="row projects-row">
          {/* <div className="col-lg-4 col-md-6">
            <div className="img-container" data-aos="fade" data-aos-delay="100">
              <img
                className="img-fluid"
                src="/images/project-happyhelpers.jpg"
                alt="happyhelpers"
              />

              <div className="overlay">
                <p>
                  Created an information website about a service company. Happy
                  Helpers is a trusted & reliable professional home services.
                </p>
                <a
                  href="https://happyhelpers.ph/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn">
                    Visit Website <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="img-container" data-aos="fade" data-aos-delay="100">
              <img
                className="img-fluid"
                src="/images/project-kontraktor.jpg"
                alt="kontraktor"
              />

              <div className="overlay">
                <p>
                  Created an information website about a construction company.
                  Kontraktor is dedicated in building residential and commercial
                  projects.
                </p>
                <a
                  href="https://kontraktorph.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn">
                    Visit Website <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="img-container" data-aos="fade" data-aos-delay="100">
              <img
                className="img-fluid"
                src="/images/project-ieo.jpg"
                alt="ieo"
              />

              <div className="overlay">
                <p>
                  Created an information/portfolio website about an interior
                  design firm. IEO Interior Design and Construction is a
                  full-service interior design and a construction firm.
                </p>

                <a
                  href="https://www.ieodesigns.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn">
                    Visit Website <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="img-container" data-aos="fade" data-aos-delay="100">
              <img
                className="img-fluid"
                src="/images/project-mundo.jpg"
                alt="mundo"
              />

              <div className="overlay">
                <p>
                  Created an information/portfolio website about a construction
                  firm. Mundo Builders is a one-stop design and construction
                  firm.
                </p>
                <a
                  href="https://www.mundobuilders.com/corporate003.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn">
                    Visit Website <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div> */}

          <div className="col-lg-4 col-md-6">
            <div className="img-container" data-aos="fade" data-aos-delay="100">
              <img
                className="img-fluid"
                src="/images/project-proshop.jpg"
                alt="proshop"
              />

              <div className="overlay">
                <p>
                  A self project, created an online store with the same
                  functionality of modern e-commerce website. It's a full stack
                  application. Used MERN Stack (MongoDB, Express, ReactJS,
                  NodeJS) with Redux.
                </p>
                <a
                  href="https://arwyne-proshop.herokuapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn">
                    Visit Website <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="img-container" data-aos="fade" data-aos-delay="100">
              <img
                className="img-fluid"
                src="/images/project-cutnshave.jpg"
                alt="cutnshave"
              />

              <div className="overlay">
                <p>
                  Created a booking application using the MERNG Stack (MongoDB,
                  Express, ReactJS, NodeJS, GraphQL). This project is about
                  booking of barbershop online.
                </p>
                <a
                  href="https://cut-n-shave.herokuapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn">
                    Visit Website <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="img-container" data-aos="fade" data-aos-delay="100">
              <img
                className="img-fluid"
                src="/images/project-carhub.jpg"
                alt="carhub"
              />

              <div className="overlay">
                <p>
                  Created an asset management system using Laravel and MySQL.
                  The project is about Car Rental System.
                </p>
                <a
                  href="https://carhub.arwynedeguzman.com/cars"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn">
                    Visit Website <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="img-container" data-aos="fade" data-aos-delay="100">
              <img
                className="img-fluid"
                src="/images/project-covid19quickie.jpg"
                alt="covid19quickie"
              />

              <div className="overlay">
                <p>
                  A mini project that can help people assess their status
                  regarding Covid-19. Used PHP and MySQL database to build the
                  website.
                </p>
                <a
                  href="http://covid19quickie.arwynedeguzman.com/views/home.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn">
                    Visit Website <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="img-container" data-aos="fade" data-aos-delay="100">
              <img
                className="img-fluid"
                src="/images/project-akyatph.jpg"
                alt="akyatph"
              />

              <div className="overlay">
                <p>
                  A static website showcasing a topic of my choice. Used HTML,
                  CSS and Bootstrap to build the website.
                </p>
                <a
                  href="https://arwyne.gitlab.io/akyat-ph/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn">
                    Visit Website <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="img-container" data-aos="fade" data-aos-delay="100">
              <img
                className="img-fluid"
                src="/images/project-natours.jpg"
                alt="natours"
              />

              <div className="overlay">
                <p>
                  A static website showcasing CSS effects and animations. Used
                  HTML and SASS to build the website.
                </p>
                <a
                  href="https://arwyne.github.io/natour/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn">
                    Visit Website <i class="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Projects
